import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import { initialize } from 'react-ga';

import Layout from '../components/layout';
import Main from '../components/talking-cahps/talkingCahpsMainContent';

/**
 * TalkingCAHPS page component. Parent component.
 * @component
 *
 * @param   {string} location - URL data object
 * @returns {component}       - <TalkingCAHPS location={string} />
 */

const TalkingCAHPS = ({ location }) => {
  initialize('UA-33936956-01', {
    debug: true,
    titleCase: false,
  });

  const { contentfulTalkingCahps } = useStaticQuery(graphql`
    query TalkingCahps {
      contentfulTalkingCahps {
        header
        subtext {
          childMarkdownRemark {
            html
          }
        }
        inputPlaceholder
        inputBtn
        confirmationMessage {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        background {
          fluid(quality: 95, maxWidth: 1900) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Layout location={location} showcase>
      <Main data={contentfulTalkingCahps} short />
    </Layout>
  );
};

TalkingCAHPS.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export default TalkingCAHPS;
